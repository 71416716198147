<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add mb-1">
      <b-col cols="12" md="5" xl="4">
        <b-form-checkbox
          v-model="reportSearchSection"
          @change="
            () => (this.showReportSearchSection = !this.showReportSearchSection)
          "
          ><b>show section</b></b-form-checkbox
        >
      </b-col>
    </b-row>

    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->

      <b-col
        v-if="reportSearchSection"
        cols="12"
        md="5"
        xl="4"
        class="invoice-actions"
      >
        <!-- Action Buttons -->

        <b-card>
          <!-- <b-tabs justified>
            <b-tab @click="onTabChange" title="Single"> -->
          <b-form-group label="Report Type">
            <b-form-radio-group
              @change="onReportChange"
              id="radio-group-3"
              v-model="reportTypeValue"
              name="radio-sub"
            >
              <b-form-radio name="some-radios1" value="default"
                >Default Report</b-form-radio
              >
              <b-form-radio name="some-radios1" value="territory"
                >Report With Territory
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group label="Show Report By" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              @change="onOrderChange"
              id="radio-group-2"
              v-model="orderValue"
              :aria-describedby="ariaDescribedby"
              name="radio-sub-component"
            >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="vendor"
                >Licensor</b-form-radio
              >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="movie"
                >Movie</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
          <b-form-group label="Report View" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="rightsView"
              v-model="rightsView"
              :aria-describedby="ariaDescribedby"
              name="radio-sub-rightsView"
            >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="some-rights"
                value="rights"
                >Type 1(Grouped Rights)</b-form-radio
              >
              <b-form-radio
                :aria-describedby="ariaDescribedby"
                name="some-subRights"
                value="subRights"
                >Type 2 (Ungrouped-Rights)</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
          <div v-if="orderValue == 'vendor'">
            <b-form-group label="Licensor">
              <v-select
                @input="getLicensorMovies('no')"
                v-model="selectedCustomer"
                placeholder="Licensor"
                label="company"
                :options="optionVendors"
              />
            </b-form-group>
            <b-form-group label="Movie">
              <v-select
                @input="onSelect($event)"
                v-model="selectedMovie"
                placeholder="Movie"
                label="multi_movies"
                :options="optionMultipleMovies"
              />
            </b-form-group>
          </div>
          <div v-else>
            <b-form-group label="Movie">
              <v-select
                @input="getMoviesLicensor"
                v-model="selectedMovie"
                placeholder="Movie"
                label="full_title"
                :options="optionMovies"
              />
            </b-form-group>
            <b-form-group label="Licensor">
              <v-select
                v-model="selectedCustomer"
                placeholder="Licensor"
                label="company"
                :options="optionCustomer"
              />
            </b-form-group>
          </div>

          <b-form-group v-if="tabIndex == 0" label="Revenue Date Range">
            <flat-pickr
              v-model="revenueDateRange"
              placeholder="Revenue Date"
              class="form-control"
              :config="{ mode: 'range', ...$flatPickrConfig }"
            />
          </b-form-group>
          <b-form-group
            v-if="
              tabIndex == 0 &&
              this.rights_tree.length &&
              rightsView == 'subRights'
            "
            label="Rights To Ungroup"
          >
            <treeselect
              v-model="report_rights"
              :options="rights_tree"
              multiple
              placeholder="Rights"
            />
          </b-form-group>
          <b-button
            v-if="tabIndex == 0"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="getReportContracts"
            :disabled="
              (selectedCustomer || selectedMovie) &&
              revenueDateRange &&
              revenueDateRange.split('to').length == 2
                ? false
                : true
            "
          >
            Fetch Royalty Report
          </b-button>
          <!-- </b-tab>
            <b-tab @click="onTabChange" title="Multiple" lazy>
              <b-form-group label="Licensor">
                <v-select
                  @input="getLicensorMovies('yes')"
                  v-model="selectedCustomer"
                  placeholder="Licensor"
                  label="company"
                  :options="optionVendors"
                />
              </b-form-group>
              <b-form-group label="Movie">
                <v-select
                  v-model="selectedMovie"
                  placeholder="Movie"
                  label="multi_movies"
                  :options="optionMultipleMovies"
                />
              </b-form-group>

              <b-form-group label="Revenue Date Range">
                <flat-pickr
                  v-model="revenueDateRange"
                  placeholder="Revenue Date"
                  class="form-control"
                  :config="{ mode: 'range', ...$flatPickrConfig }"
                />
              </b-form-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="mb-75"
                block
                @click="getReportContracts"
                :disabled="
                  !selectedCustomer ||
                  !selectedMovie ||
                  !revenueDateRange ||
                  revenueDateRange.split('to').length < 2
                    ? true
                    : false
                    ? true
                    : false
                "
              >
                Generate Report
              </b-button>
            </b-tab>
          </b-tabs> -->

          <b-button
            v-if="tabIndex == 2 || tabIndex == 1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="searchSavedReports"
          >
            Search
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            block
            @click="resetForm"
          >
            Reset
          </b-button>

          <!-- Button: Print -->
        </b-card>

        <table
          style="background: whiteSmoke"
          v-if="royaltyReportData && tabIndex == 0"
        >
          <tr>
            <td class="text-wrap text-left">
              Deduction P&A From Cinematic Gross Receipts (Cost Off Top Deal)
            </td>
            <td>
              {{
                royaltyReportData.Deduction_from_Rental_PA[0] == "1"
                  ? "Yes"
                  : "No"
              }}
            </td>
          </tr>
          <tr>
            <td class="text-wrap text-left">
              Deduction MG From Cinematic Gross Receipts
            </td>
            <td>
              {{
                royaltyReportData.Deduction_from_Rental_MG[0] == "1"
                  ? "Yes"
                  : "No"
              }}
            </td>
          </tr>
          <tr>
            <td class="text-wrap text-left">
              Distributor Corridor Prior To Recoupement Of P&A
            </td>
            <td>
              {{
                royaltyReportData
                  .Distributor_Share_prior_to_recoupement_on_PA[0] == "1"
                  ? "Yes"
                  : "No"
              }}
            </td>
          </tr>
          <tr>
            <td class="text-wrap text-left">
              Distributor Corridor Prior To Recoupement of MG
            </td>
            <td>
              {{
                royaltyReportData
                  .Distributor_Share_prior_to_recoupement_on_MG[0] == "1"
                  ? "Yes"
                  : "No"
              }}
            </td>
          </tr>
          <tr>
            <td class="text-wrap text-left">
              Distributor Corridor Prior To Recoupement Of P&A and MG
            </td>
            <td>
              {{
                royaltyReportData.Distributor_Commission_on_Rental[0] == "1"
                  ? "Yes"
                  : "No"
              }}
            </td>
          </tr>
          <tr>
            <td class="text-wrap text-left">
              Licensor Corridor Prior To Recoupement Of P&A
            </td>
            <td>
              {{
                royaltyReportData
                  .Licensor_Share_prior_to_recoupement_on_PA[0] == "1"
                  ? "Yes"
                  : "No"
              }}
            </td>
          </tr>
          <tr>
            <td class="text-wrap text-left">
              Licensor Corridor Prior To Recoupement Of MG
            </td>
            <td>
              {{
                royaltyReportData
                  .Licensor_Share_prior_to_recoupement_on_MG[0] == "1"
                  ? "Yes"
                  : "No"
              }}
            </td>
          </tr>
        </table>
      </b-col>
      <b-col
        cols="12"
        :xl="reportSearchSection ? '8' : '12'"
        :md="reportSearchSection ? '7' : '12'"
      >
        <div>
          <b-tabs pills v-model="tabIndex">
            <b-tab title="Royalty Report" active>
              <b-alert show variant="dark " class="alert-heading">
                <b-row>
                  <div style="overflow-x: auto; white-space: nowrap">
                    <div v-if="selectedRotaltyContracts.length">
                      <span>Selected Reports</span>

                      <b-badge variant="light-primary" @click="onGenrateAll"
                        >generate for all selected</b-badge
                      >
                    </div>
                    <b-list-group horizontal>
                      <b-list-group-item
                        v-for="(item, index) in selectedRotaltyContracts"
                        :key="index"
                        :active="
                          selectedContractID == item.contract_id ? true : false
                        "
                        @click="getRoyaltyReportMultiple(item)"
                        >Report {{ index + 1 }}</b-list-group-item
                      >
                    </b-list-group>
                  </div>
                </b-row>

                <h4
                  v-if="royaltyReportData && royaltyReportData.statement_period"
                  class="alert-heading"
                >
                  <span>
                    <b
                      >Licensor :
                      {{ selectedCustomer ? selectedCustomer.company : "" }}<br
                    /></b>
                    <b
                      >Movie : {{ royaltyReportData.isMultipleMovies.toString()
                      }}<br
                    /></b>
                    <b
                      >Statement Period :
                      {{ royaltyReportData.statement_period }}</b
                    >
                  </span>
                </h4>

                <h4 v-else class="alert-heading">
                  For royalty report please provide required fields and click
                  generate report.
                </h4>

                <div
                  v-if="royaltyReportData && royaltyReportData.statement_period"
                >
                  <b-row class="m-1">
                    <b-col md="6">
                      <b-form-group label="sent to">
                        <b-form-input
                          id="event-description"
                          v-model="reportSentTo"
                        >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Comments"
                        label-for="event-description"
                      >
                        <b-form-textarea
                          id="event-description"
                          v-model="reportDescription"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-alert>
              <div
                v-if="royaltyReportData && royaltyReportData.statement_period"
              >
                <table
                  style="border: 1.5px solid black"
                  ref="rrt_table"
                  id="rrt_table"
                >
                  <tr>
                    <th class="text-left" colspan="3">Rental Theatrical</th>
                    <th>Admissions</th>
                    <th>Box Office</th>
                    <th>Rental</th>
                  </tr>
                  <tr>
                    <td class="smallWidth text-left" colspan="3">
                      Accumulated
                    </td>

                    <td>
                      <span>{{ royaltyReportData.accuAddmissionSum }} </span>
                    </td>
                    <td>
                      <span
                        >CHF
                        {{
                          formatCurrency(
                            Number(royaltyReportData.accuBoxOfficeSum)
                          )
                        }}
                      </span>
                    </td>
                    <td>
                      <span
                        >CHF
                        {{
                          formatCurrency(
                            Number(royaltyReportData.accuRentalSum)
                          )
                        }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left" colspan="3">This Period</td>

                    <td>
                      <span>{{ royaltyReportData.periodAddmissionSum }} </span>
                    </td>
                    <td>
                      <span
                        >CHF
                        {{
                          formatCurrency(
                            Number(royaltyReportData.periodBoxOfficeSum)
                          )
                        }}
                      </span>
                    </td>
                    <td>
                      <span
                        >CHF
                        {{
                          formatCurrency(
                            Number(royaltyReportData.periodRentalSum)
                          )
                        }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left" colspan="3">Total</th>
                    <th>{{ royaltyReportData.totalAddmissionSum }}</th>
                    <th>
                      CHF
                      {{
                        formatCurrency(
                          Number(royaltyReportData.totalBoxOfficeSum)
                        )
                      }}
                    </th>
                    <th>
                      CHF
                      {{
                        formatCurrency(Number(royaltyReportData.totalRentalSum))
                      }}
                    </th>
                  </tr>
                  <tr class="spacing">
                    <th colspan="6"></th>
                  </tr>
                  <tr>
                    <th class="text-left" colspan="6">Less</th>
                  </tr>
                  <tr>
                    <td class="smallWidth text-left" colspan="3">P&A</td>
                    <td class="smallWidth text-left" colspan="2">
                      <div style="text-align: right" class="text-nowrap">
                        Accumulated:
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(royaltyReportData.accu_expense_amount)
                            )
                          }}</b
                        >
                      </div>

                      <div style="text-align: right" class="text-nowrap">
                        This Period:<b>
                          CHF
                          {{
                            formatCurrency(
                              Number(royaltyReportData.period_expense_amount)
                            )
                          }}</b
                        >
                      </div>
                    </td>

                    <td>
                      <b>
                        CHF
                        {{
                          formatCurrency(
                            Number(royaltyReportData.total_expense_amount)
                          )
                        }}</b
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left" colspan="5">MG</td>

                    <td>
                      CHF
                      {{ formatCurrency(Number(royaltyReportData.MG_PA)) }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      Number(
                        royaltyReportData.distributor_Share_prior_to_recoupement_new
                      ) > 0
                    "
                  >
                    <td class="text-left" colspan="4">
                      Distributor Corridor Prior To Recoupement Of P&A
                    </td>
                    <td>
                      <p>
                        {{
                          royaltyReportData.distributor_Share_prior_to_recoupement_percent
                        }}
                        %
                      </p>
                    </td>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(
                                royaltyReportData.distributor_Share_prior_to_recoupement_new
                              )
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>

                  <tr
                    v-if="
                      Number(
                        royaltyReportData.Distributor_Share_prior_to_recoupement_on_MG_val
                      ) > 0
                    "
                  >
                    <td class="text-left" colspan="4">
                      Distributor Corridor Prior To Recoupement Of MG
                    </td>
                    <td>
                      <p>
                        {{
                          royaltyReportData.Distributor_Share_prior_to_recoupement_on_MG_percent
                        }}
                        %
                      </p>
                    </td>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(
                                royaltyReportData.Distributor_Share_prior_to_recoupement_on_MG_val
                              )
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      Number(
                        royaltyReportData.Distributor_Commission_on_Rental_val
                      ) > 0
                    "
                  >
                    <td colspan="4" class="text-left">
                      Distributor Corridor Prior To Recoupement Of P&A And MG
                    </td>
                    <td>
                      <p>
                        {{
                          royaltyReportData.Distributor_Commission_on_Rental_percent
                        }}
                        %
                      </p>
                    </td>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(
                                royaltyReportData.Distributor_Commission_on_Rental_val
                              )
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      Number(
                        royaltyReportData.Licensor_Share_prior_to_recoupement_on_PA_val
                      ) > 0
                    "
                  >
                    <td colspan="4" class="text-left">
                      Licensor Corridor Prior To Recoupement Of P&A
                    </td>
                    <td>
                      <p>
                        {{
                          royaltyReportData.Licensor_Share_prior_to_recoupement_on_PA_percent
                        }}
                        %
                      </p>
                    </td>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(
                                royaltyReportData.Licensor_Share_prior_to_recoupement_on_PA_val
                              )
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      Number(
                        royaltyReportData.Licensor_Share_prior_to_recoupement_on_MG_val
                      ) > 0
                    "
                  >
                    <td colspan="4" class="text-left">
                      Licensor Corridor Prior To Recoupement Of MG
                    </td>
                    <td>
                      <p>
                        {{
                          royaltyReportData.Licensor_Share_prior_to_recoupement_on_MG_percent
                        }}
                        %
                      </p>
                    </td>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(
                                royaltyReportData.Licensor_Share_prior_to_recoupement_on_MG_val
                              )
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="5" class="text-left">Total</th>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(
                                royaltyReportData.allocation_torwards_pa_final
                              )
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>
                  <tr class="spacing">
                    <th colspan="6"></th>
                  </tr>
                  <tr>
                    <th colspan="5" class="text-left">
                      Cinematic Share Post Recoupment
                    </th>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(royaltyReportData.theatrical_overages)
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>

                  <tr class="spacing">
                    <th colspan="6"></th>
                  </tr>
                  <tr>
                    <td colspan="4" class="text-left">
                      Licensor's Share Of Overage
                    </td>
                    <td>
                      <p>
                        {{
                          royaltyReportData.Distributor_Theatrical_Share_percent
                        }}
                        %
                      </p>
                    </td>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(
                                royaltyReportData.Distributor_Theatrical_Share
                              )
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>
                  <tr class="spacing">
                    <th colspan="6"></th>
                  </tr>

                  <tr>
                    <td colspan="6">
                      <table
                        v-for="(
                          itemMain, indexMain
                        ) in royaltyReportData.licensorArray"
                        :key="indexMain"
                      >
                        <tr style="background-color: #eaecfd; color: black">
                          <td class="text-left">
                            {{ royaltyReportData.movie_names[indexMain] }}
                          </td>
                          <td>Accumulated Revenue</td>
                          <td>Revenue for the Period</td>
                          <td>Total Revenue</td>
                          <td>Share Percent</td>
                          <td></td>
                        </tr>

                        <tr v-for="(item, index) in itemMain" :key="index">
                          <td class="text-left">
                            Licensor's Share Of {{ item.rightName }}<br />
                            <span class="text-muted">
                              <small class="text-muted">
                                {{
                                  item.subrightsNamesString
                                    ? "( " + item.subrightsNamesString + " )"
                                    : ""
                                }}</small
                              >
                            </span>
                          </td>

                          <td>
                            CHF
                            {{
                              item.sumBilledAcc
                                ? formatCurrency(Number(item.sumBilledAcc))
                                : "0.00"
                            }}
                          </td>
                          <td>
                            CHF
                            {{
                              item.sumBilledPeriod
                                ? formatCurrency(Number(item.sumBilledPeriod))
                                : "0.00"
                            }}
                          </td>
                          <td>
                            CHF
                            {{
                              item.sumBilledTotal
                                ? formatCurrency(Number(item.sumBilledTotal))
                                : "0.00"
                            }}
                          </td>
                          <td>
                            {{ item.rightPercent ? item.rightPercent : "0" }}
                            %
                          </td>
                          <td>
                            CHF
                            {{
                              item.totalFinal
                                ? formatCurrency(Number(item.totalFinal))
                                : "0.00"
                            }}
                          </td>
                        </tr>

                        <tr
                          v-for="(
                            itemNew, indexNew
                          ) in royaltyReportData.miscellaneous_revenue_array"
                          :key="indexNew"
                        >
                          <td class="text-left" colspan="4">
                            {{ itemNew.name }}
                          </td>
                          <td>100 %</td>
                          <td>
                            {{ formatCurrency(itemNew.amount) }}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>

                  <!-- <tr>
                    <td colspan="5">
                      LICENSOR CORRIDOR PRIOR TO RECOUPEMENT OF MG
                    </td>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(
                                royaltyReportData.Licensor_Share_prior_to_recoupement_on_MG_val
                              )
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr> -->
                  <tr>
                    <th colspan="5" class="text-left">Total Licensor Share</th>

                    <td>
                      <p>
                        <b
                          >CHF
                          {{
                            formatCurrency(royaltyReportData.liencer_sum1)
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>
                  <tr class="spacing">
                    <th colspan="6"></th>
                  </tr>
                  <!-- <tr>
                    <td colspan="5">
                      LICENSOR CORRIDOR PRIOR TO RECOUPEMENT OF P&A
                    </td>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(
                                royaltyReportData.Licensor_Share_prior_to_recoupement_on_PA_val
                              )
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr> -->

                  <tr>
                    <th colspan="5" class="text-left">
                      Total With Licensor Corridor Prior To Recoupment
                    </th>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              royaltyReportData.Total_with_Licensor_Corridor_prior_to_recoupment
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>

                  <tr class="spacing">
                    <th colspan="6"></th>
                  </tr>
                  <tr>
                    <th colspan="6" class="text-left">Less Paid</th>
                  </tr>
                  <tr>
                    <td colspan="5" class="text-left">MG</td>
                    <td>
                      CHF
                      {{
                        formatCurrency(
                          Number(royaltyReportData.Already_paid_MG)
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" class="text-left">Bumper Paid</td>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(royaltyReportData.BUMPER_PAID)
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" class="text-left">Overage Paid</td>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(royaltyReportData.OVERAGES_PAID)
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" class="text-left">Divers Paid</td>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(royaltyReportData.DIVERS_PAID)
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>
                  <tr
                    v-for="(
                      itemM, indexM
                    ) in royaltyReportData.new_revenue_types_array"
                    :key="indexM"
                  >
                    <td colspan="5" class="text-left">{{ itemM.name }}</td>
                    <td>
                      <p>
                        <b>
                          CHF
                          {{ formatCurrency(Number(itemM.amount)) }}</b
                        >
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="5" class="text-left">
                      {{
                        royaltyReportData.Balance_to_your_favor &&
                        Number(royaltyReportData.Balance_to_your_favor) < 0
                          ? "Unrecouped"
                          : "Balance To Your Favour"
                      }}
                    </th>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(royaltyReportData.Balance_to_your_favor)
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>
                  <tr class="spacing">
                    <th colspan="6"></th>
                  </tr>
                  <tr v-if="royaltyReportData.showCoridorSection == true">
                    <th class="text-left" colspan="6">
                      Licensor Share Of Corridor
                    </th>
                  </tr>

                  <tr v-if="royaltyReportData.showCoridorSection == true">
                    <td class="text-left" colspan="5">
                      Licensor Share Prior To Recoupement On P&A
                    </td>
                    <td>
                      CHF
                      {{
                        formatCurrency(
                          Number(
                            royaltyReportData.Licensor_Share_prior_to_recoupement_on_PA_val
                          )
                        )
                      }}
                    </td>
                  </tr>
                  <tr v-if="royaltyReportData.showCoridorSection == true">
                    <td class="text-left" colspan="5">
                      Licensor Share Prior To Recoupement On MG
                    </td>
                    <td>
                      CHF
                      {{
                        formatCurrency(
                          Number(
                            royaltyReportData.Licensor_Share_prior_to_recoupement_on_MG_val
                          )
                        )
                      }}
                    </td>
                  </tr>
                  <tr v-if="royaltyReportData.showCoridorSection == true">
                    <th class="text-left" colspan="5">
                      Total Licensor Corridor
                    </th>
                    <th>
                      CHF
                      {{
                        formatCurrency(
                          Number(
                            parseFloat(
                              royaltyReportData.Licensor_Share_prior_to_recoupement_on_PA_val
                            ) +
                              parseFloat(
                                royaltyReportData.Licensor_Share_prior_to_recoupement_on_MG_val
                              )
                          )
                        )
                      }}
                    </th>
                  </tr>
                  <tr v-if="royaltyReportData.showCoridorSection == true">
                    <td class="text-left" colspan="5">
                      Less Licensor Corridor Paid
                    </td>
                    <td>
                      CHF
                      {{
                        formatCurrency(
                          Number(royaltyReportData.Less_Licensor_corridor_Paid)
                        )
                      }}
                    </td>
                  </tr>
                  <tr v-if="royaltyReportData.showCoridorSection == true">
                    <th class="text-left" colspan="5">Balance Payable</th>

                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(royaltyReportData.Balance_payable)
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>
                  <tr class="spacing">
                    <th colspan="6"></th>
                  </tr>
                  <tr v-if="royaltyReportData.showCoridorSection == true">
                    <th class="text-left" colspan="5">Total Payable</th>
                    <td>
                      <p>
                        <b>
                          CHF
                          {{
                            formatCurrency(
                              Number(royaltyReportData.total_payable)
                            )
                          }}</b
                        >
                      </p>
                    </td>
                  </tr>

                  <!-- <tr class="spacing">
                    <th class="text-center" colspan="6">
                    
                    </th>
                  </tr> -->
                </table>

                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  class="m-2"
                >
                  <b-button
                    :disabled="loading ? true : false"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="gradient-primary"
                    class="ml-2"
                    @click="showGenerateConfirm(royaltyReportData)"
                  >
                    <b-spinner v-if="loading" small /> Generate Royalty Report
                  </b-button>
                </div>
                <b-row> </b-row>
              </div>
            </b-tab>
            <b-tab title="Existing Royalty Reports">
              <b-alert variant="secondary" show>
                <b-row cols="12" class="mb-1 alert-heading">
                  <b-col
                    class="d-flex align-items-center justify-content-between"
                  >
                    <b-form-radio-group
                      @change="GetSavedReports"
                      id="radio-group-1"
                      v-model="selectedRadio"
                      :options="optionsRadio"
                      name="radio-options"
                    ></b-form-radio-group>
                  </b-col>
                </b-row>
              </b-alert>
              <b-card no-body>
                <b-table
                  hover
                  small
                  bordered
                  :fields="fields"
                  :items="savedReports"
                  empty-text="No  records found"
                  show-empty
                  responsive
                >
                  <template #cell(Sr)="data">
                    <div class="text-nowrap text-center">
                      {{ data.index + 1 }}
                      <h6 v-if="data.item.isFinalReport == 1">
                        <b-badge variant="light-danger">{{
                          data.item.isFinalReport == 1 ? "Final/Last" : ""
                        }}</b-badge>
                      </h6>
                      <h6 v-if="data.item.isReportSent == 1">
                        <b-badge variant="light-danger">{{
                          data.item.isReportSent == 1 ? "Sent" : ""
                        }}</b-badge>
                      </h6>
                      <hr />
                      <b-button
                        v-if="data.item.reportContractId"
                        @click.stop="
                          $router.push(
                            `/contracts/form/${data.item.reportContractId}`
                          )
                        "
                        size="sm"
                        variant="link"
                        ><b><u>View Contract</u></b></b-button
                      >
                    </div>
                  </template>
                  <template #cell(dateFrom)="data">
                    {{
                      data.value
                        ? moment(data.item.dateFrom).format("DD/MM/YYYY") +
                          " to " +
                          moment(data.item.dateTo).format("DD/MM/YYYY")
                        : ""
                    }}
                  </template>

                  <template #cell(balance_to_favor)="data">
                    <b>{{
                      "CHF " + formatCurrency(data.item.balance_to_favor)
                    }}</b>
                  </template>

                  <template #cell(createdAt)="data">
                    {{
                      data.value ? moment(data.value).format("DD/MM/YYYY") : ""
                    }}
                  </template>

                  <template #cell(actions)="data">
                    <div class="text-nowrap">
                      <b-button
                        class="mr-1"
                        variant="outline-primary"
                        size="sm"
                        @click="onPreviewRoyalty(data.item.royalty_report_id)"
                        >Preview</b-button
                      >

                      <b-button
                        variant="outline-danger"
                        size="sm"
                        @click="
                          onDownloadRoyalty(
                            data.item.royalty_report_document,
                            data.item.company
                          )
                        "
                        >Download</b-button
                      >
                      <br />

                      <b-button
                        block
                        class="mt-1"
                        variant="outline-warning"
                        size="sm"
                        @click="
                          updateFinalStatus(
                            data.item.royalty_report_id,
                            data.item.isFinalReport,
                            data.item.isReportSent,
                            'sent'
                          )
                        "
                        >{{
                          data.item.isReportSent == 0 ? "Set " : "Unset"
                        }}
                        Report as Sent</b-button
                      >
                      <b-button
                        block
                        class="mt-1"
                        variant="outline-warning"
                        size="sm"
                        @click="
                          updateFinalStatus(
                            data.item.royalty_report_id,
                            data.item.isFinalReport,
                            data.item.isReportSent,
                            'final'
                          )
                        "
                        >{{
                          data.item.isFinalReport == 0 ? "Set " : "Unset"
                        }}
                        Report as final/last</b-button
                      >
                      <!-- <b-tooltip
                        title="Preview Report"
                        class="cursor-pointer"
                        :target="`invoice-row-${data.item.royalty_report_id}-send-icon`"
                      /> -->
                    </div>
                  </template>
                </b-table>
                <template #footer>
                  <div class="mx-2 mb-2">
                    <b-row>
                      <!-- Pagination -->
                      <b-col
                        class="d-flex align-items-center justify-content-center justify-content-sm-center"
                      >
                        <b-pagination
                          @change="onPageChange"
                          v-model="pagination.currentPage"
                          :total-rows="pagination.total"
                          :per-page="pagination.perPage"
                          first-number
                          last-number
                          class="mb-0 mt-1 mt-sm-0"
                          prev-class="prev-item"
                          next-class="next-item"
                          :limit="15"
                        >
                          <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                        </b-pagination>
                      </b-col>
                    </b-row>
                  </div>
                </template> </b-card
            ></b-tab>
            <!-- <b-tab title="Pending Royalty Reports As Per Statement Period">
             
              <div v-if="tabIndex == 2">
                <pending-royalty-report
                  ref="pendingComponentRef"
                  @setReportDataEmit="getReportData"
                  :selectedVendorId="
                    selectedCustomer ? selectedCustomer.vendor_id : null
                  "
                  :selectedMovieId="
                    selectedMovie && selectedMovie.movie_id
                      ? selectedMovie.movie_id
                      : null
                  "
                />
              </div>
            </b-tab> -->
          </b-tabs>
        </div>
      </b-col>
    </b-row>
    <div>
      <b-modal
        scrollable
        v-model="modalContract"
        id="modalContract"
        size="xl"
        hide-footer
      >
        <b-table
          bordered
          class="position-relative"
          primary-key="contract_id"
          responsive
          show-empty
          :items="contractsList"
          :fields="fieldsContract"
          hover
        >
          <!-- hover -->
          <template #head(selected)="data">
            <b-form-checkbox
              @change="selectAllRows"
              v-model="selectAllStatus"
              value="1"
              unchecked-value="0"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(selected)="data">
            <b-form-checkbox
              v-model="selectedRotaltyContracts"
              :value="data.item"
            >
            </b-form-checkbox>
          </template>
          <template #cell(contract_id)="data">
            {{ data.item.contract_id }}
          </template>

          <template #cell(company)="data">
            <div style="width: max-content">
              <b-badge variant="light-success">
                {{ data.item.contract_nature
                }}{{ data.item.draft ? "(draft)" : "" }}</b-badge
              ><br />{{ data.item.company }}
            </div>
          </template>
          <template #cell(title)="data">
            <div style="width: max-content" v-html="data.value"></div>
          </template>
          <template #cell(territory)="data">
            <span>
              {{ data.value }}
            </span>
          </template>
          <template #cell(right)="data">
            <span>
              {{ data.value }}
            </span>
          </template>
          <template #cell(language)="data">
            <span>
              {{ data.value }}
            </span>
          </template>
          <template #cell(purchaseDate)="data">
            <b-badge variant="light-info"
              >Contract Date : {{ data.item.purchaseDate }} </b-badge
            ><br />
            <b-badge variant="light-success" class="mt-1">
              Start : {{ data.item.rStart }} </b-badge
            ><br />

            <b-badge variant="light-danger" class="mt-1">
              End : {{ data.item.rExpiry }}</b-badge
            >
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                variant="primary"
                size="sm"
                @click="getRoyaltyReportMultiple(data.item)"
              >
                Preview Report </b-button
              ><br />
              <b-badge
                variant="warning"
                v-if="selectedContractID === data.item.contract_id"
                >currently on preview</b-badge
              >

              <!-- <b-button
                size="sm"
                @click="data.toggleDetails, getRoyaltyReportMultiple(data.item)"
              >
                {{ data.detailsShowing ? "Hide" : "Show" }} Details
              </b-button> -->
            </div>
          </template>
          <template #row-details="row"> </template>
          <template #cell()="data">
            <span class="text-nowrap">{{ data.value }}</span>
          </template>
        </b-table>
      </b-modal>
    </div>
    <b-modal
      scrollable
      v-model="modalPreview"
      id="modalPreview"
      size="xl"
      hide-footer
    >
      <preview-invoice
        :selected_royalty_report_id="selected_royalty_report_id"
      />
    </b-modal>

    <!-- <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import Treeselect from "@riophae/vue-treeselect";
import { labels } from "@/utils/constants";
import * as ContractServices from "@/apiServices/ContractServices";
import previewInvoice from "./PreviewRoyaltyReport.vue";
import pendingRoyaltyReport from "./PendingRoyaltyReports.vue";

import {
  GetRoyaltyReportDataMultiple,
  GetRoyaltyReportList,
  GenerateRoyaltyReportPDF,
  GetMoviesVendor,
  GetVendorMovies,
} from "@/apiServices/RevenueServies";
import { formatCurrency } from "@/utils/helpers";

import { getAllMovie } from "@/apiServices/MovieServices";
import { getAllVendor } from "@/apiServices/VendorServices";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BAlert,
  BLink,
  VBToggle,
  BTable,
  BTooltip,
  BSpinner,
  BPagination,
  BTabs,
  BTab,
  BModal,
  BBadge,
  BFormRadio,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
// import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
// import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'

export default {
  components: {
    BRow,
    BTabs,
    BTab,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    flatPickr,
    vSelect,
    Logo,
    BTable,
    BTooltip,
    ToastificationContentVue,
    BSpinner,
    BPagination,
    BModal,
    Treeselect,
    BBadge,
    BFormRadio,
    previewInvoice,
    BFormRadioGroup,
    pendingRoyaltyReport,
    BListGroup,
    BListGroupItem,
    // InvoiceSidebarSendInvoice,
    // InvoiceSidebarAddPayment,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },

  data() {
    return {
      selectedRadio: "all",
      optionsRadio: [
        { text: "All", value: "all" },
        { text: "Sent", value: "isReportSent" },
        { text: "Final/Last", value: "isFinalReport" },
      ],
      BASE_URL_FILES: process.env.VUE_APP_FILESURL,
      moment: moment,
      pagination: {
        currentPage: 1,
        total: null,
        perPage: 10,
      },
      selectAllStatus: "0",
      selectedRotaltyContracts: [],
      selected_royalty_report_id: null,
      perPageOptions: [10, 25, 50, 100],
      fields: [
        "Sr",
        { key: "company", label: "Licensor" },
        // { key: "movie_title", label: "Movie" },

        // { key: "territory_name", label: "Territory" },
        { key: "dateFrom", label: "Duration" },
        { key: "balance_to_favor", label: "Balance to favor/Unrecouped" },

        { key: "createdAt", label: "Report Generate Date" },
        { key: "reportSentTo", label: "Sent to" },
        { key: "reportDescription", label: "Comments" },
        { key: "actions", label: "Actions" },
      ],
      selectedCustomer: null,
      orderValue: "vendor",
      rightsView: "rights",
      reportTypeValue: "default",
      loading: false,
      tabIndex: 0,
      modalContract: false,
      modalPreview: false,
      revenueDateRange: null,
      optionCustomer: [],
      optionCustomerTemp: [],
      optionMovies: [],
      optionMultipleMovies: [],
      optionVendors: [],
      optionAvailableTeritory: [],
      rights_tree: [],
      report_rights: [],
      selectedMovie: null,
      movieName: null,
      reportContractId: null,
      selectedContractID: null,
      optionTeritory: [],
      contractsList: [],
      allTeritory: [],
      savedReports: [],
      vendorAllReports: [],
      trl_territories: [],
      selectedTeritory: null,
      royaltyReportData: null,
      isFinalReport: false,
      finalReportstatus: false,
      isGenerateAll: false,
      reportDescription: null,
      reportSentTo: null,
      reportSearchSection: true,
      showReportSearchSection: true,
      fieldsContract: [
        { key: "selected", label: "", stickyColumn: true },

        {
          key: "actions",
          label: "Actions",
          stickyColumn: true,
          variant: "primary",
        },

        {
          key: "company",
          label: "Contact",
        },
        {
          key: "title",
          label: "Movie",
        },
        {
          key: "territory",
          label: "territory",
        },
        {
          key: "right",
          label: "rights",
        },
        {
          key: "language",
          label: "languages",
        },
        {
          key: "purchaseDate",
          label: "contract dates",
        },

        {
          key: "language",
          label: "languages",
        },
        { key: "contract_id", label: "Contract ID" },
      ],
    };
  },

  mounted() {},

  async beforeMount() {
    this.GetSavedReports();
    this.GetAllVendor();
    this.GetAllMovie();
    // await this.$store.dispatch("master/setLabelTreeMaster", {
    //   key: labels.territory_tree,
    //   master: labels.territory,
    //   sub_master: labels.sub_territory,
    // });

    // this.trl_territories = this.$store.state.master.territory_tree;
  },
  watch: {
    // limit() {
    //   this.getAllRevenueData();
    // },
    // currentPage() {
    //   this.getAllRevenueData();
    //   this.$refs["table_container"].scrollIntoView({ behavior: "smooth" });
    // },
    selectedRotaltyContracts() {
      if (this.selectedRotaltyContracts.length > 0) {
        if (this.selectedRotaltyContracts.length != this.contractsList.length) {
          this.selectAllStatus = "0";
        } else {
          this.selectAllStatus = "1";
        }
      } else {
      }
    },
  },
  methods: {
    formatCurrency,

    async onDownloadRoyalty(file, outputFileName) {
      const fileName = this.BASE_URL_FILES + file;

      try {
        const response = await fetch(fileName);
        const blob = await response.blob();
        const url = await URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = outputFileName + ".pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        console.log({ err });
      }
    },
    async onGenrateAll() {
      this.isGenerateAll = true;

      for (let i of this.selectedRotaltyContracts) {
        this.loading = true;
        await this.getRoyaltyReportMultiple(i);

        await this.GenerateRoyaltyReportPdf(this.royaltyReportData);
      }
      this.loading = false;
      this.tabIndex = 1;
      this.GetSavedReports();
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: "Generated Succesfully",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    selectAllRows() {
      if (this.selectAllStatus == "1") {
        this.selectedRotaltyContracts = this.contractsList;
      } else {
        this.selectedRotaltyContracts = [];
      }
    },
    async getReportData(data) {
      let nextFromDate = data.lastReportCreatedDate
        ? moment(data.lastReportCreatedDate).format("YYYY-MM-DD")
        : moment(data.contractCreatedDate).format("YYYY-MM-DD");
      let nextToDate = null;
      if (data.statementPeriod == "1") {
        nextToDate = moment(nextFromDate).add(1, "months");
      } else if (data.statementPeriod == "2") {
        nextToDate = moment(nextFromDate).add(6, "months");
      } else if (data.statementPeriod == "3") {
        nextToDate = moment(nextFromDate).add(3, "months");
      } else if (data.statementPeriod == "4") {
        nextToDate = moment(nextFromDate).add(12, "months");
      }
      nextToDate = moment(nextToDate).format("YYYY-MM-DD");
      this.revenueDateRange = nextFromDate + " to " + nextToDate;
      this.getRoyaltyReportMultiple(data);
    },
    async onPreviewRoyalty(id) {
      this.selected_royalty_report_id = id;
      this.modalPreview = true;
    },
    async onReportChange() {
      if (this.reportTypeValue == "territory") {
        this.$router.push(`/generateroyaltyterritory`);
      }
    },
    async onOrderChange() {
      this.selectedMovie = null;
      this.selectedCustomer = null;
      this.revenueDateRange = null;
      this.royaltyReportData = null;
      if (this.orderValue == "vendor") {
        //this.GetAllVendor();
      } else if (this.orderValue == "movie") {
        // this.GetAllMovie();
      }
    },
    async GetSavedReports() {
      try {
        let payload = {
          page: this.pagination.currentPage,
          reportContractId: this.reportContractId,
          limit: this.pagination.perPage ? this.pagination.perPage : null,
          vendor_id:
            this.selectedCustomer && this.selectedCustomer.vendor_id
              ? this.selectedCustomer.vendor_id
              : null,
          movie_id:
            this.selectedMovie && this.selectedMovie.movie_id
              ? this.selectedMovie.movie_id
              : null,
          territory_id: this.selectedTeritory ? this.selectedTeritory : null,
          selectedRadio: this.selectedRadio,
          // range: this.revenueDateRange
          //   ? this.revenueDateRange.split("to")
          //   : null,
        };

        const response = await GetRoyaltyReportList(payload);

        if (!response.status) {
          return this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Data not found!",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.savedReports = response.data.data.data;
        if (
          response.data.data.hasOwnProperty("pagination") &&
          response.data.data.pagination.total
        ) {
          this.pagination.total = response.data.data.pagination.total;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },
    async GetAllMovie() {
      try {
        const response = await getAllMovie();
        if (response.data) {
          this.optionMovies = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },

    async GetAllVendor() {
      try {
        const response = await getAllVendor({});
        if (response.data) {
          this.optionVendors = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },
    async updateFinalStatus(reportId, finalStatus, isReportSent, type) {
      try {
        let final;
        let sent;
        if (type == "final") {
          final = finalStatus && finalStatus == 1 ? 0 : 1;
        } else {
          final = finalStatus;
        }

        if (type == "sent") {
          sent = isReportSent && isReportSent == 1 ? 0 : 1;
        } else {
          sent = isReportSent;
        }

        const response = await GenerateRoyaltyReportPDF({
          royalty_report_id: reportId,
          finalStatus: final,
          isReportSent: sent,
        });
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.GetSavedReports();
        }
      } catch (error) {
        console.log("Error in updating status");
      }
    },
    onSelect(selectedMovie) {
      // console.log(selectedMovie);
      // let getData = this.vendorAllReports.filter((z) => {
      //   return (
      //     z.movie_id == selectedMovie.multi_movies_ids &&
      //     String(z.vendor_id) == String(selectedMovie.vendor_id)
      //   );
      // });
      // console.log(getData, "movie");
      // if (getData.length) {
      //   this.revenueDateRange = getData[0].dateTo;
      // } else {
      //   this.revenueDateRange = "";
      // }
    },
    onTabChange() {
      this.selectedMovie = null;
      this.movieName = null;
      this.selectedCustomer = null;
      this.revenueDateRange = null;
    },

    onPageChange() {
      this.$nextTick(() => {
        this.GetSavedReports();
      });
    },

    async getLicensorMovies(isMultiple) {
      this.optionMultipleMovies = [];
      this.vendorAllReports = [];
      this.selectedMovie = null;
      try {
        const response = await GetVendorMovies({
          vendor_id: this.selectedCustomer.vendor_id,
          isMultiple: isMultiple,
        });
        if (response.data.status) {
          this.optionMultipleMovies = response.data.data.vendors;
          this.vendorAllReports = response.data.data.reportStrtDate;
          if (this.optionMultipleMovies.length == 1) {
            this.selectedMovie = this.optionMultipleMovies[0];
            //this.onSelect(this.selectedMovie);
          }
        }
      } catch (error) {
        console.log("Error in getting Contracts List");
      }
    },
    async getMoviesLicensor() {
      this.optionCustomer = [];
      this.selectedCustomer = null;

      try {
        const response = await GetMoviesVendor({
          movie_id: this.selectedMovie.movie_id,
        });
        if (response.data.status) {
          this.optionCustomer = response.data.data;
        }
      } catch (error) {
        console.log("Error in getting Contracts List");
      }
    },
    searchSavedReports() {
      this.tabIndex = this.tabIndex;
      this.royaltyReportData = null;
      if (this.tabIndex == 2) {
        this.$refs.pendingComponentRef.getContractsList();
      }

      this.GetSavedReports();
    },

    async GenerateRoyaltyReportPdf(payload) {
      this.loading = true;
      try {
        payload.searchValues = {
          vendor_id: this.selectedCustomer.vendor_id,
          movie_id:
            this.selectedMovie && this.selectedMovie.movie_id
              ? this.selectedMovie.movie_id
              : null,
          reportContractId: this.reportContractId,
          territory_id: 0,
          expense_date: this.revenueDateRange,
        };
        let vendorData = this.optionVendors.filter((x) => {
          return x.vendor_id == this.selectedCustomer.vendor_id;
        });
        if (vendorData.length) {
          this.selectedCustomer = vendorData[0];
        }
        payload.headData = {
          territory_name: "",
          tilte_name:
            this.selectedMovie && this.selectedMovie.title
              ? this.selectedMovie.title
              : null,
          vendor_name: this.selectedCustomer,
        };
        payload.isFinalReport = this.isFinalReport == true ? 1 : 0;
        payload.reportDescription = this.reportDescription;
        payload.reportSentTo = this.reportSentTo;
        payload.isRRTerritoryWise = false;
        let tempReportHtml = this.$refs.rrt_table;
        payload.reportHtml = tempReportHtml.outerHTML;

        const response = await GenerateRoyaltyReportPDF(payload);
        if (response.data.status) {
          if (this.isGenerateAll == false) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "PDF Generated Succesfully",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.selected_royalty_report_id =
              response.data.data.royalty_report_id;
            this.modalPreview = true;
            this.GetSavedReports();
          }
          // this.$router.push({
          //   name: "PreviewRoyaltyReport",
          //   params: {
          //     royalty_report_id: response.data.data.royalty_report_id,
          //   },
          // });
          //this.searchSavedReports();
          this.loading = false;

          //this.optionCustomer = response.data.data.rows;
        }
      } catch (err) {
        this.loading = false;
        console.log("Error in adding ", err);
      }
    },

    async getReportContracts() {
      try {
        const response = await ContractServices.getAllContract({
          movie:
            this.selectedMovie && this.selectedMovie.multi_movies_ids
              ? this.selectedMovie.multi_movies_ids
              : this.selectedMovie && this.selectedMovie.movie_id
              ? this.selectedMovie.movie_id
              : null,
          vendor: this.selectedCustomer
            ? this.selectedCustomer.vendor_id
            : null,
          contract_nature: "Acquisition",
        });
        this.contractsList = response.data.data.contracts;

        if (!response.data.data.contracts.length) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Acquisition Contract Not Found!",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        //  else if (this.contractsList.length == 1) {
        //   this.getRoyaltyReportMultiple(this.contractsList[0].contract_id);
        // }
        else {
          this.modalContract = true;
        }
      } catch (error) {
        console.log("Error in getting Contracts List", error);
      }
    },
    async getRoyaltyReportMultiple(contractData) {
      if (this.selectedContractID != contractData.contract_id) {
        this.rights_tree = [];
        this.report_rights = [];
      }

      this.tabIndex = 0;
      let obj = {
        vendor_id: contractData.vendor_id,
        company: contractData.company,
      };

      this.selectedCustomer = obj;

      this.modalContract = false;
      this.reportContractId = contractData.contract_id;

      try {
        let movie_array = [];
        let selected_m_id = contractData.movie_ids.split(",");

        for (let i of selected_m_id) {
          let getMovie = this.optionMovies.filter((y) => {
            return y.movie_id == i;
          });

          if (getMovie.length > 0) {
            let obj = {
              title: getMovie[0].title,
              movie_id: i,
            };
            movie_array.push(obj);
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Something Went Wrong in Selecting Contract",
                icon: "EditIcon",
                variant: "danger",
              },
            });
            break;
            return;
          }
        }

        let payload = {
          contractID: contractData.contract_id,
          vendor_id: contractData.vendor_id,
          movie_id: movie_array,
          territory_id: this.selectedTeritory ? this.selectedTeritory : null,
          fromDate: this.revenueDateRange
            ? this.revenueDateRange.split("to")[0]
            : "",
          toDtae: this.revenueDateRange
            ? this.revenueDateRange.split("to")[1]
            : "",
          rightsView: this.rightsView,
          report_rights: this.report_rights,
        };
        const response = await GetRoyaltyReportDataMultiple(payload);
        if (response.data) {
          this.royaltyReportData = response.data.data;

          if (
            this.royaltyReportData.licensorArray &&
            this.royaltyReportData.licensorArray.length &&
            this.selectedContractID != contractData.contract_id
          ) {
            for (let i of this.royaltyReportData.licensorArray[0]) {
              if (this.rightsView == "subRights") {
                let index = this.rights_tree.findIndex((x) => x.id == i.id);
                console.log(index, "i");
                if (index >= 0) {
                  this.rights_tree[index].children.push({
                    key: i.id,
                    id: i.id,
                    lbl_id: i.id,
                    title: i.subrightsNamesString,
                    label: i.subrightsNamesString,
                    value: i.id,
                  });
                } else {
                  let objSub = {
                    key: i.id,
                    id: i.id,
                    lbl_id: i.id,
                    title: i.rightName,
                    label: i.rightName,
                    value: i.id,
                    lbl_is_group: 0,
                    // children: [
                    //   {
                    //     key: i.sub_id,
                    //     id: i.sub_id,
                    //     lbl_id: i.sub_id,
                    //     title: i.rightName,
                    //     label: i.rightName,
                    //     value: i.sub_id,
                    //   },
                    // ],
                  };
                  this.rights_tree.push(objSub);
                }
              } else {
                let obj = {
                  key: i.id,
                  id: i.id,
                  lbl_id: i.id,
                  title: i.rightName,
                  label: i.rightName,
                  value: i.id,
                  lbl_is_group: 0,
                };
                this.rights_tree.push(obj);
              }
            }
          }
          this.selectedContractID = contractData.contract_id;

          this.GetSavedReports();
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },
    showGenerateConfirm(data) {
      this.$bvModal
        .msgBoxConfirm(
          "Once royalty report gets generated it will get added to system logs!.Are you sure, you want to proceed with generate royalty report?",
          {
            title: "Confirmation",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) this.GenerateRoyaltyReportPdf(data);
        })
        .catch((err) => {
          // An error occurred
        });
    },
    resetForm() {
      this.selectedMovie = null;
      this.selectedCustomer = null;
      this.revenueDateRange = null;
      this.reportContractId = null;
      this.selectedRotaltyContracts = [];
      this.royaltyReportData = null;
      this.GetSavedReports();
    },
  },
  created() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #000;
  text-align: left;
  padding: 5px;

  text-align: right;
}

.spacing {
  background-color: #eaecfd;
}
br {
  border: 1px solid #000;
}
table {
  font-family: verdana !important;
}
table {
  table-layout: fixed !important;
}
td {
  width: 33% !important;
}
</style>
